
/* Color palette */
$primary-color: #F08D47;
$menu-item-active:#4782F0;
$secondary-color: #006FBA;
$input-color:#999999;
$light-white: #FFFFFF40;
$primary-light-color: #0D494E10;
$primary-black: #4B4B4B;
$dark-blue: #22509D;
$light-grey: #00000015;
$dark-grey: #8898AA;
$success-color: #40CE2A;
$danger-color: #FE0000;
$warning-color: #F3B21B;
$body-bg: #F8F7F4;
$table-bg: #F7FAFC;
$input-bg: #F8FBFC;
$border-color:#d9d9d9;
$badge-violet: #7F47F0;
$modal-red: #F64667;
$failed-color:#FF7676;

/* Shadows */
$primary-shadow: 0 8px 22px #0000001A;
$secondary-shadow: 0px 3px 6px #2C28281C;


/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;
