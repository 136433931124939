@import "./../../../styles/variables";

.success-modal {
  // width: 562px;
  &.link-copy-modal{
    width: 900px !important; 
    .ant-modal-content{
      width: 900px !important;
    }
  }
  .ant-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 166px;
    background: transparent linear-gradient(180deg, #f08d47 0%, #eea849 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    .ant-modal-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      i,
      span {
        background-color: #fff;
        border-radius: 50%;
        padding: 20px;
        color: $primary-color;
        margin-top: 25px;
        margin-bottom: 15px;
      }
      span {
        &.anticon-close,
        &.anticon-exclamation {
          background-color: $modal-red;
          color: #fff;
        }
      }
      h2 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
  .ant-modal-content {
    text-align: center;
    height: max-content;
    border-radius: 18px;
    overflow: hidden;
    // width: 900px !important;
    button {
      color: #fff;
      span {
        font-size: 18px;
      }
    }
  }
  .ant-modal-body {
    text-align: center;
    padding: 30px 50px;
    h3 {
      font-size: 18px;
    }
    h2{
      font-family: ProximaNovaAltBold !important;
    }
    h4 {
      font-size: 17px;
      span {
        font: normal normal bold 24px/26px ProximaNovaBold;
        &.failure {
          color: $modal-red;
        }
        &.success {
          color: $success-color;
        }
      }
    }
  }
}
