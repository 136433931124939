@import './../../../styles/variables';

.filter-drawer{
    .ant-drawer-content{
        .ant-drawer-wrapper-body{
            padding: 25px;
        }
    }
    .ant-drawer-body{
        height: 100%;
        form{
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            .ant-col{
                h3{
                    font-size: 14px;
                    color: #9E9E9E;
                }
                .ant-checkbox-wrapper{
                    font-size: 15px;
                    color: #4F4F4F;
                }
            }
        }
    }
    .ant-drawer-header{
        border-bottom: none;
        padding: 16px 24px 0px 24px;
        .ant-drawer-title{
            h1{
                font-size: 14px;
                font-weight: 100;
                font: normal normal bold 24px/17px Proxima Nova;
            }
        }
    }
    .ant-checkbox-group{
        display: flex !important;
        flex-direction: column !important;
    }
    .anticon-close{
        font-size: 22px;
        font-weight: bolder;
        color: #000;
        padding-bottom: 15px;
    }
}