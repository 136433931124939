@import './../../styles/variables';

.hiring-tests__list{
    &-header{
        margin-bottom: 25px;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 18px;
        flex-wrap: nowrap;
        >div{
            width: 100%;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            background: #FFFFFF;
            box-shadow: 0px 3px 24px #00000005;
            border-radius: 8px;
            min-height: 138px;
            &:not(:last-child){
                margin-right: 0px;
            }
            h1{
                font-size: 40px;
                margin-bottom: 0 ;
            }
            p{
                font-size: 14px;
                color: #4F4F4F;
            }
        }
    }
    &-table{
        height: 73vh !important;
        p.status{
            padding: 4px 18px;
            text-align: center;
            width: max-content;
            margin-top: 10px;
            border-radius: 8px;
            text-transform: capitalize;
            &.active{
                background-color: #DFFCE7;
                color: #25BD4F;
            }
            &.expired{
                background-color: #FCDFDF;
                color: #E93333;
            }
            &.not_deployed{
                background-color: #DFF2FC;
                color: #1AA1E9;
            }
        }
        tr{
            &.ant-table-row{
                td{
                    padding: 0 8px !important;
                }
                td:last-child{
                    display: grid;
                    grid-template-columns: .2fr auto;
                    align-items: center;
                    justify-content: flex-start;
                    p{}
                    div{
                        display: none;
                        margin-left: 5px;
                        i{
                            font-size: 1rem;
                            padding: 5px;
                            margin: 0 4px;
                            // margin: 0 8px;
                        }
                    }
                }
            }
            &:hover{
                &.ant-table-row{
                    td{
                    &:last-child{
                        transition: all .14s ease-in;
                        margin-left: -55px;
                        p{}
                        div{
                            display:flex !important;
                            position: absolute;
                            right: 0;
                            i:hover{
                                background-color: #f08d473a;
                                padding: 5px;
                                border-radius: 15px;
                            }
                        }
                    }
                    &.actions{
                        margin-left: 0px;
                    }
                }
            }
            }
        }
    }
    
}
.ant-popover.ant-popover-placement-top {
    .ant-popover-inner-content{
        padding: 3px 10px;
        p{
            margin: 0 !important;
            color: #fff;
        }
        background-color: $primary-color;
        border-radius: 4px;
    }
    .ant-popover-arrow{
        &-content{
            background-color: $primary-color;
        }
    }
}

.hiring-tests__list-table .ant-table-body table colgroup{
    min-width: 250px !important;
}

.hiring-tests__list-table{
    .ant-table-tbody,.ant-table-thead{
        tr{
            td,th{
                &:nth-child(4),&:nth-child(3),&:nth-child(2){
                    text-align: center;
                }
                &.actions{
                    text-align: left;
                }
            }
        }
    }
}