@import './../../styles/variables';

.earn__certificate{
    .test__view-menubar{
        margin: 0 0 0 30px;
        .add-question{
            padding: 0 25px;
            i{
                margin-right: 10px;
                &::before{
                    color: #fff;
                }
            }
        }
        .certificate-tests{
            &-card{
                cursor: pointer;
                min-height: 120px;
            }
        }
        .earn__certificate-content{
            .list__tests{
                overflow-y: scroll;
                // height: 100%;
                max-height: 88vh;
                padding-bottom: 25px;
                &::-webkit-scrollbar {
                    width: 0px;
                  }
                  
                  /* Track */
                  &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px #fff; 
                    border-radius: 10px;
                  }
                   
                  /* Handle */
                  &::-webkit-scrollbar-thumb {
                    background: rgb(209, 209, 209); 
                    border-radius: 10px;
                  }
                .test_card{
                    cursor: pointer;
                    display: grid;
                    grid-template-columns: .8fr 1fr;
                    overflow: hidden;
                    border-radius: 12px;
                    height: 134px;
                    background: #fff;
                    .image-wrapper{
                        position: relative;
                        object-fit: contain;
                        max-width: 100%;
                        img{
                            width: 100%;
                            height: 134px;
                        }
                    }
                    .test-info{
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        padding: 25px 18px;
                        flex-direction: column;
                        h3{
                            min-height: 48px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            .search-box{
                h2{
                    font: normal normal bold 18px/24px ProximaNovaBold !important;
                }
                .list-languages{
                    .ant-table-body{
                        table{
                            td{
                                padding: 22px 8px !important;
                                font: normal normal normal 16px/26px ProximaNovaRegular !important;
                                border-bottom: 1px solid #eee !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.topics-filter{
    .ant-drawer-body{
        overflow: hidden;
    }
    .topics{
        &::-webkit-scrollbar{
            width: 0;
        }
        height: 580px;
    }
}

.select_test-modal{
    .all__tests{
        margin-bottom: 45px;
        &-controls{
            button{
                height: 39px;
                padding: 0px 30px;
            }
        }
    }
}