@import './../../../styles/variables';


.manage__question-table {
    table {
      tr {
        td {
          &:last-child {
            p{
                margin: 0;
            }
            div.switch-wrapper {
                display: none;
                button{
                    &.ant-switch-checked{
                        background-color: #40CE2A ;
                    }
                }
            }
          }
        }
        &:hover{
            &.ant-table-row{
                td:last-child{
                    p{}
                    div.switch-wrapper{
                        display:block;
                    }
                }
            }
        }
      }
    }
  }
  
  .manage__question-table .ant-table-body table colgroup {
    // min-width: 290px !important;
  }
  
  .manage__question-table .ant-table-body table {
    padding-bottom: 10px !important;
  }

  .manage-question{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }