@import "./../../../styles/variables";

.test__view {
  &-banner {
    background: $primary-color 0% 0% no-repeat padding-box;
    width: 100%;
    min-height: 120px;
    border-radius: 12px;
    padding: 25px 30px;
    margin-bottom: 20px;
    position: relative;
    * {
      color: #fff;
    }
    .title__container {
      max-width: 87%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      .title {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        span {
          font-size: 18px;
        }
        > * {
          margin-right: 12px;
          cursor: pointer;
        }
        h1 {
          font: normal normal bold 24px/28px Proxima Nova;
        }
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        margin-left: 30px;
        p{
          margin: 0;
        }
        button.ant-btn.ant-btn-default.transparent-round{
          margin-bottom: 0px;
        }
      }
    }
    .active-actions {
      position: absolute;
      right: 30px;
      display: flex;
      &:not(.active){
        right: 5px;
        top: 10px;
      }
      &.expired{
        right: 30px;
        top: unset;
      }
      button{
        height: 35px;
        padding: 0 20px;
        font-size: 14px;
        text-transform: capitalize;
        &:last-child{
          cursor: default !important;
        }
      }
      .blue,.transparent:not(:last-child) {
        margin-right: 8px;
      }
      &.Not {
        top: 5px;
        right: 0px;
      }
    }
    .footer {
      text-align: right;
      width: max-content;
      min-width: 290px;
      position: absolute;
      right: 0;
      bottom: 10%;
      ul {
        background-color: transparent !important;
        li {
          height: 35px;
        }
        .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child{
          border: none !important;
        }
      }
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item{
        &:hover::after,&-selected::after{
          border-bottom: 2px solid #fff !important;
        }
      }
      .blue {
        padding: 0 28px;
        margin-right: 18px;
        margin-bottom: 11px;
      }
    }
  }
  &-numbers {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    > div {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 3px 24px #00000005;
      border-radius: 8px;
      &:not(:last-child) {
        margin-right: 0px;
      }
      h1 {
        font-size: 40px;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        color: #4f4f4f;
      }
    }
  }
  &-table {
    height: 100% !important;
    table {
      tr:not(.ant-table-placeholder) {
        td {
          &:last-child {
            display: flex;
            align-items: center;
            justify-content: space-around;
            div {
              color: $menu-item-active;
            }
          }
        }
      }
    }
    // &.certificate{
    //   .ant-table-body table colgroup{
    //     width: 450px;
    //   }
    //   table {
    //     tr:not(.ant-table-placeholder) {
    //       td {
    //         &:last-child {
    //           display: flex;
    //           align-items: center;
    //           justify-content: space-between;
    //           div {
    //             color: $menu-item-active;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
  &-content {
    // height: 65vh;
    .all-questions {
      .questions__list,.question__view{
        height: 70vh;
        margin-top: 50px;
      }
      .questions__list{
        p{
          &.bold {
            font-weight: bolder;
          }
        }
      }
      button{
        position: absolute;
        left: 0;
        top: -5px;
        height: 39px;
        padding: 0 30px;
        width: fit-content;
    }
    }
    .no-questions {
      height: 100%;
      min-height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      h3{
        font-weight: bold;
        margin: 0;
      }
      img {
        height: 180px;
        width: 180px;
        margin-bottom: 20px;
      }
      button{
        padding: 0 28px;
      }
    }
  }
  &-menubar{
    .ant-tabs-nav{
      padding: 0 30px;
    }
    .add-question{
      position: absolute;
      right: 30px;
      height: 38px;
      padding: 0 30px;
    }
  }
}

.test__view-table .ant-table-body table colgroup {
  // min-width: 200px !important;
}

.test__view-table .ant-table-body table {
  // padding-bottom: 10px !important;
}

p.report {
  margin-bottom: 0;
}

.deploy-test-modal{
  h2{
    font: normal normal bold 22px/27px ProximaNovaBold !important;
  }
  &-form{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    &-fields{
      margin-bottom: 35px;
      input{
        margin-bottom: 20px;
        width: 48%;
      }
      .menubar{}
      .note{
        p{
          margin-bottom: 5px;
          &:first-child{
            text-decoration: underline;
          }
          span{
            font-family:ProximaNovaBold;
          }
        }
      }
    }
    &-actions{
      button{
        height: 40px;
        padding: 0 35px;
      }
    }
  }
}

.edit-time-modal{
  &-form{
    &-fields{
      margin-bottom: 20px;
      width: 75%;
    }
    &-actions{
      display: flex;
      justify-content: flex-end;
      button{
        height: 36px;
        padding: 0 28px;
      }
    }
  }
  .ant-input-group-addon{
    border: none;
    background: transparent !important;
  }
}

.contribution-link-modal{
  .ant-modal-header{
    h1{
      text-align: center;
    }
  }
  &-form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .contribution-link{
      margin-bottom: 25px;
      align-items: baseline;
      input.link{
        background: #eee;
      }
      button{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 55px;
        font-size: 1.3rem;
        border-radius: 10px !important;
      }
      i.icon-refresh{
        &::before{
          color: #fff;
        }
      }
    }
    >.ant-row button.blue{
      padding: 0 35px;
    }
  }
}