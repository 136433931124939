.newquestion-title{
    font: 24px/17px;
    color: #000;
    font-weight: bold;
}

.newquestion__container{
    height: 85vh;
    max-width: 100vw;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #00000005;
    border-radius: 8px;
    padding: 50px;
}