@import './../../../styles/variables';

#tutorial-mask{
    z-index: 9999;
    top: 0;
    left: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.72);
}