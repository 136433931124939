@import './../../../styles/variables';

.create__form{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    &-fields{
        .questions{
            width: 81%;
            margin-bottom: 30px;
            align-items: flex-start;
            .title{
                >div{
                    width: 100%;
                }
                .ant-upload-list.ant-upload-list-text{
                    position: absolute !important;
                    max-width: 250px !important;
                }
                .ant-upload-text{
                    i{
                        margin-left: 20px;
                        color: $menu-item-active;
                    }
                }
                
            }
            .difficulty-level{
                align-items: flex-end;
                p{
                    margin-bottom: .5em;
                }
                .field-label{
                    color: #bebebe;
                    font-size: 15px;
                }
                .ant-radio-group.ant-radio-group-outline .ant-radio-wrapper{
                    margin-right: 30px !important;
                }
            }
            >div{
                display: flex;
                margin-bottom: 30px;
                &:last-child{
                    width: 99%;
                    div{
                        margin-right: 25px;
                        width: 50%;
                    }
                }
            }
            textarea{
                max-height: 100px;
            }
            .ant-select{
                .ant-select-selector{
                    border: none;
                    border-bottom: 1px solid #d9d9d9;
                    border-color: #d9d9d9 !important;
                    box-shadow: none !important;
                    &:active,&:hover,&:focus{
                        box-shadow: none;
                        border: none;
                        border-bottom:1px solid $input-color;
                    }
                }
                width: 100% !important;
                >div{
                    width: 100% !important;
                }
            }
            
        }
        .answers{
            // width: 45%;
            display: flex;
            flex-direction: column;
            h2{
                margin-bottom: 20px;
                font: normal normal 600 18px/17px ProximaNovaRegular !important;
                color: #000000;
            }
            .ant-radio-group.ant-radio-group-outline{
                width: 100% !important;
            }                                                              
            .ant-space.ant-space-vertical{
                width: 100% !important;
            }
            .ant-radio-wrapper{
                // width: 88%;
                .ant-radio{
                    width: 100% !important;
                }
                > span{
                    &:last-child{
                        width: 100% !important;
                        >div{
                            width: 90% !important;
                        }
                    }
                }
            }
            .answer-row{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .answer-input{
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    .answer-input-field{
                        width: 84% !important;
                    }
                }
                .ant-skeleton.ant-skeleton-with-avatar{
                    width: max-content;
                }
                i{
                    margin: 1px 10px;
                    cursor: pointer;
                    &.icon-delete{
                        font-size: 14px;
                        color: #FF7676;
                        margin-bottom: 8px;
                    }
                    &.icon-image{
                        color: $menu-item-active;
                    }
                }
                .ant-upload-list.ant-upload-list-text{
                    position: absolute;
                }
                .ant-radio-checked .ant-radio-inner {
                    border-color: $primary-color;
                    &:hover,&:active,&:focus{
                        border-color: $primary-color;    
                    }
                }
                .ant-upload-select-text{
                    max-width: 100%;
                }

                .ant-radio-wrapper{
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                }

                >label{
                    >span{
                        &:first-child{
                            span{
                                &:after{
                                    background-color: $primary-color;
                                }
                            }
                        }
                        &:last-child{
                            display: flex;
                            align-items: baseline;
                            justify-content: flex-start;
                            >div{
                                width: 450px;
                                // width: 100%;
                            }i{
                                margin: 1px 10px;
                                &.icon-delete{
                                    color: #FF7676;
                                }
                                &.icon-image{
                                    color: $menu-item-active;
                                }
                            }
                            span{
                                // margin-top: 4px;
                                // width: 30px !important;
                            }
                        }
                    }
                }
            }
            >button{
                color: $primary-color;
                border:1px solid $primary-color !important;
                background-color: #fff !important;
                margin-left: 8px;
                // width: 30%;
                width: max-content;
                padding:5px 30px;
            }
        }
    }
    &-actions{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button{
            padding:5px 30px;
        }
    }
}

// .success-modal{
//     width: 562px !important;
//     .ant-modal-header{
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         height: 166px;
//         background: transparent linear-gradient(180deg, #F08D47 0%, #EEA849 100%) 0% 0% no-repeat padding-box;
//         opacity: 1;
//         .ant-modal-title{
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             flex-direction: column;
//             i{
//                 background-color: #fff;
//                 border-radius: 50%;
//                 padding: 20px;
//                 color: $primary-color;
//                 margin-top: 25px;
//                 margin-bottom: 15px;
//             }
//             h2{
//                 color: #fff;
//                 font-size: 1.5rem;
//                 font-weight: 600;
//             }
//         }
//     }
//     .ant-modal-content{
//         text-align: center;
//         height: 281px;
//         border-radius: 18px;
//         overflow: hidden;
//         button{
//             color: #fff;
//             span{
//                 font-size: 20px;
//             }
//         }
//     }
//     .ant-modal-body{
//         h3{
//             font-size: 18px;
//         }
//     }
// }

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft{
    padding: 12px;
}

.ant-radio-group.ant-radio-group-outline{
    display: flex !important;
    // flex-direction: column !important;
    // padding: 6px 10px !important;
    .ant-radio-wrapper{
        display: flex !important;
    }
}

.contibute-more-modal{
    button{
        span{
            font-size: .93rem !important;
        }
        padding: 0 20px;
        height: 30px;
    }
}