@import "src/styles/variables";

@font-face {
    font-family: 'icomoon';
    src:  url('../assets/icons/icomoon.eot?qx99t7');
    src:  url('../assets/icons/icomoon.eot?qx99t7#iefix') format('embedded-opentype'),
      url('../assets/icons/icomoon.ttf?qx99t7') format('truetype'),
      url('../assets/icons/icomoon.woff?qx99t7') format('woff'),
      url('../assets/icons/icomoon.svg?qx99t7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-delete-1:before {
    content: "\e905";
    color: #ff7676;
  }
  .icon-image:before {
    content: "\e90c";
    color: #4782f0;
  }
  .icon-certificate:before {
    content: "\e900";
    color: #f08d47;
  }
  .icon-contribute-questions:before {
    content: "\e901";
    color: #6a6a6a;
  }
  .icon-copy-link:before {
    content: "\e902";
  }
  .icon-correct-answer:before {
    content: "\e903";
    color: #40ce2a;
  }
  .icon-date:before {
    content: "\e904";
    color: #999;
  }
  .icon-delete:before {
    content: "\e906";
  }
  .icon-deploy-a-hiring-tets:before {
    content: "\e907";
    color: #6a6a6a;
  }
  .icon-earn-a-certificate:before {
    content: "\e908";
    color: #6a6a6a;
  }
  .icon-edit-2:before {
    content: "\e909";
    color: #eea849;
  }
  .icon-edit:before {
    content: "\e90a";
  }
  .icon-filter:before {
    content: "\e90b";
    color: #eea849;
  }
  .icon-logout:before {
    content: "\e90e";
    color: #6a6a6a;
  }
  .icon-manage-hiring-tests:before {
    content: "\e90f";
    color: #6a6a6a;
    font-size: 1.2rem;
  }
  .icon-my-profile:before {
    content: "\e910";
    color: #6a6a6a;
  }
  .icon-note:before {
    content: "\e911";
    color: #4782f0;
  }
  .icon-placeholder:before {
    content: "\e912";
    color: #f08d47;
  }
  .icon-refresh:before {
    content: "\e913";
    color: #7f47f0;
  }
  .icon-success:before {
    content: "\e914";
    color: #f08d47;
  }
  .icon-take-a-hiring-test:before {
    content: "\e915";
    color: #6a6a6a;
  }
  .icon-time:before {
    content: "\e916";
    color: #6a6a6a;
  }
  .icon-topics:before {
    content: "\e917";
    color: #6a6a6a;
  }
  .icon-upload:before {
    content: "\e918";
    color: #eea849;
  }