@import './../../../styles/variables';

.pending-approval__question{
    .correct_answer_image{
        max-height: 200px;
        max-width: 350px;
      }
      .answers_grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;
        .answer_item{
          border-radius: 8px;
          padding: 20px;
          border: 1px solid rgba(128, 128, 128, 0.253);
          img{
            max-width: 240px;
            max-height: 185px;
          }
        }
      }
    &-header{
        .title{
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            >div{
                &:first-child{
                    >*{
                        margin-right: 15px;
                    }
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                }
                &:last-child{
                    p{
                        font-size: 14px;
                        color: $primary-color;
                        span{
                            color: #000;
                            font-weight: 600;
                        }
                    }
                }
            }
            span,h1{
                font-weight: 600 !important;
            }
            h1{
                font-size: 1.5rem;
            }
            >span{
                font-size: 1.2rem;
            }
            button{
                cursor: default;
                height: 30px !important;
            }
        }
    }
    &-content{
        position: relative;
        border-radius: 8px;
        height: 85vh;
        overflow-y: scroll;
        max-width: 100vw;
        background-color: #fff;
        padding: 50px 60px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 20px;
        &::-webkit-scrollbar{
            width: 0;
          }
        .edit-icon {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
            i{
                font-size: 1.2rem;
                color: $primary-color;
            }
        }
        .texts{
            .correct_answer-header,.answer-header{
                margin-top: 40px;
            }
            p{
                color: #7E7E7E;
                font-size: 1rem;
                margin-bottom: 0;
            }
            h2{
                font-weight: 600;
                color: #000;
                font-size: 1.2rem;
            }
            h3{
                color: rgba(0, 0, 0, 0.74);
                font-weight: 600;
                margin-bottom: 0;
                font-size: 1.1rem;
                display: flex;
                align-items: center;
                &:last-child{
                    color: #40CE2A;
                    *{
                        margin-right: 5px;
                    }
                }
            }
            .answers_flex,.answers_grid{
                h3{
                    color: #000 !important;
                }
            }
        }
        .image{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            flex-direction: column;
            .image-wrapper{
                width: 100%;
                height: 320px;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
            .actions{
                button{
                    padding: 0 32px;                    
                    &:last-child{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}