@import "./../../../../styles/variables";

.testview__template {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 100px auto 10px 90px;
  // user-select: none;
  &-header {
    padding: 0 30px 0 55px;
    color: #fff;
    background: #f08d47 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      text-align: left;
      font: normal normal bold 24px/28px ProximaNovaBold;
      letter-spacing: 0px;
      color: #ffffff;
    }
    .timer {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 10px 15px;
      width: fit-content;
      min-width: 239px;
      height: 50px;
      border-radius: 25px;
      background-color: $badge-violet;
      font: normal normal normal 18px/28px ProximaNovaRegular;
      i::before {
        margin-right: 10px;
        color: #fff;
      }
    }
  }
  &-body {
    user-select: none;
    padding-top: 50px;
    background: #fff 0% 0% no-repeat padding-box;
    .ant-row {
      .ant-col-14 {
        padding-right: 50px;
        .question {
          h2 {
            font: normal normal medium 22px/32px ProximaNovaAltBold;
            font-weight: bold;
          }
          img{
            width: 700px;
            max-height: 395px;
            object-fit: contain;
          }
        }
      }
      .ant-col-8 {
        .answers {
          .ant-menu.ant-menu-light.answers_grid.ant-menu-root.ant-menu-inline{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-gap: 15px;
            &::before{
              display: none;
            }
          }
          .ant-menu.ant-menu-light.answers_flex.ant-menu-root.ant-menu-inline{
            li{
              // height: 50px !important;
              height: max-content;
              white-space: initial;
            }
          }
          h3 {
            padding-top: 10px;
            font: normal normal medium 18px/32px ProximaNovaBlack !important;
            font-weight: bold;
          }
          ul {
            border: none;
            li {
              border-radius: 8px;
              font: normal normal normal 16px/35px ProximaNovaRegular;
              margin-bottom: 20px;
              height: 100%;
              padding: 6px 18px;
              border: 1px solid #d6d6d6 !important;
              .imagewrap{
                min-height: 140px;
              }
              img{
                width: 100%;
                height: 140px;
                object-fit: contain;
              }
              &::after {
                display: none;
              }
              &.ant-menu-item.ant-menu-item-only-child.ant-menu-item-active {
                color: rgba(0, 0, 0, 0.74) !important;
              }
              &.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
                background-color: #4782f0;
                background: #4782f0;
                color: #fff;
              }
              &.ant-menu-item.ant-menu-item-selected{
                background-color: #4782f0;
                background: #4782f0;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  &-progress{
    background-color: #fff;
    .ant-progress-inner{
      background-color: #D8D8D8;
    }
  }
  &-footer {
    background: #f8f7f4 0% 0% no-repeat padding-box;
    margin: auto 0;
    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px 0 55px;
      .question-count {
        font: normal normal normal 16px/24px ProximaNovaRegular;
        color: #000;
      }
      button {
        height: 40px;
        padding: 10px 30px;
      }
    }
  }
  .ant-row {
    height: 100%;
  }
}

.test__instructions-texts-list{
  h3{
    font-family: ProximaNovaBold !important;
  }
  ul,ol{
    padding-left: 20px;
  }
  ol {
    padding-left: 16px;
    li::marker{
      background-color: red !important;
      font-weight: 600;
      border: 1px solid #000;
      padding: 3px;
      font-size: 1rem;
    }
  }
}