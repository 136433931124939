@import './../../../styles/variables';

.proposed__topic{
    &-header{
        .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            >div{
                &:first-child{
                    >*{
                        margin-right: 15px;
                    }
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                }
                &:last-child{
                    button{
                        padding: 0 35px;                    
                        height: 40px !important;
                        text-transform: capitalize;
                    }
                }
            }
            span,h1{
                font-weight: 600 !important;
            }
            h1{
                font-size: 1.5rem;
            }
            >span{
                font-size: 1.2rem;
            }
        }
    }
    &-content{
        position: relative;
        border-radius: 8px;
        height: 82vh;
        max-width: 100vw;
        background-color: #fff;
        padding: 50px 60px;
        // display: grid;
        // grid-template-columns: repeat(2,1fr);
        // grid-gap: 20px;
        .edit-icon {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
            i{
                font-size: 1.2rem;
                color: $primary-color;
            }
        }
        p{
            margin-bottom: 0;
            font-size: 1rem;
        }
        img{
            margin: 0 0 20px 20px;
            float: right;
            border-radius: 12px;
        }
    }
}