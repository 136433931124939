@import "./../../../../styles/variables";

.certificate-tests {
  &-card {
    min-height: 118px;
    height: max-content;
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 20px 24px 0 24px;
    position: relative;
    box-shadow: 0px 3px 24px #00000005;
    border-radius: 8px;

    h2 {
      margin-bottom: 20px;
      font: normal normal normal 18px/24px Proxima Nova;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      i {
        top: 10%;
        right: 4%;
        position: absolute;
        color: $primary-color;
        font-size: 1.3rem;
      }
    }
    .footer {
      display: flex;
      align-items: baseline;
      // justify-content: space-between;
      h4 {
        &.attempts {
          color: #4782f0;
        }
        &.no-attempts {
          width: 80%;
          color: #ef3c3c;
        }
      }
      button {
        margin-right: 20px;
        height: 30px;
        font-size: 14px;
        // &[disabled] {
        //   color: #fff;
        //   opacity: 0.5;
        // }
      }
      p {
        text-align: center;
        width: max-content;
        margin-top: 10px;
        margin-bottom: 0;
        border-radius: 18px;
        color: $success-color;
      }
    }
  }
}
