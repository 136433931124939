@import "./../../../styles/variables";

.topic__items {
  max-height: 85vh !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    &-track {
      // background: #dad8d8;
    }
    &-thumb {
      background: rgba(136, 136, 136, 0.541);
      border-radius: 20px;
      &:hover {
        background: rgba(85, 85, 85, 0.568);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.topic-item {
  background-color: #fff;
  min-height: 332px;
  border-radius: 12px;
  padding: 0 !important;
  position: relative;
  cursor: pointer;
  &.proposed-topics{
    min-height: 290px;
    .info{
      margin: auto;
      padding-top: 35px;
    }
  }
  .ant-btn.ant-btn-text.popover-btn {
    position: absolute;
    right: 5%;
    top: 5%;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 37px;
    border-radius: 4px;
    span {
      margin-top: 5px;
      font-size: 1.6rem;
      color: #fff;
    }
  }
  .header {
    img {
      // animation: 1s ease-in-out 0s 1 fadeIn;
      width: 100%;
      height: 100%;
      min-height: 170px;
      max-height: 170px;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
    }
  }
  .info {
    padding: 15px 30px;
    .title {
      min-height: 48px;
      text-align: left;
      font: normal normal 600 16px/24px Proxima Nova;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .new-questions {
      color: #4f4f4f;
    }
  }
}

.all__tests {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  &-card {
    width: 100%;
    // width: 305px;
    cursor: pointer;
    min-height: 108px;
    border: 0.5px solid #b1b1b175;
    border-radius: 12px;
    // min-height: 150px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #00000005;
    padding: 16px 24px 0 24px;
    h3 {
      font: normal normal medium 17px/22px Proxima Nova;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        color: $primary-color;
        font-size: 1.3rem;
      }
    }
    p.status {
      padding: 2px 18px;
      text-align: center;
      width: max-content;
      margin-top: 10px;
      border-radius: 18px;
      color: #fff;
      text-transform: capitalize;
      &.easy {
        background-color: $menu-item-active;
      }
      &.medium {
        background-color: $primary-color;
      }
      &.hard {
        background-color: $badge-violet;
      }
    }
  }
}

.all__tests-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    height: 35px;
  }
}

.select_test-modal {
  .ant-modal-content {
    padding: 20px;
  }
}

.tests-badge {
  background-color: $menu-item-active;
  border-radius: 13px;
  width: 74px;
  color: #fff;
  padding: 2px 10px;
}