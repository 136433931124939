@import "./../../../../styles/variables";

.test__user {
  &-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: baseline;
    }
    .export-action {
      button {
        height: 32px;
        padding: 0 30px;
      }
    }
    h1 {
      margin-left: 15px;
    }
  }
}

.test__user-table .ant-table-body table colgroup {
  min-width: 350px !important;
}
.test__user__tags-table {
  .ant-table-thead {
    th {
      padding: 12px 0px !important;
    }
  }
}
.test__user-table .ant-table-body table,
.test__user__tags-table .ant-table-body table {
  padding-bottom: 10px !important;
  // margin-top: 15px;
  .ant-table-tbody {
    *:not(.status) {
      color: #4f4f4f !important;
    }
    td {
      padding: 0px !important;
    }
  }
  .ant-table-container {
    padding: 30px 50px !important;
  }
  p.status {
    padding: 5px 18px;
    text-align: center;
    width: max-content;
    margin-top: 10px;
    border-radius: 8px;
    text-transform: capitalize;
    &.correct {
      background-color: #dffce7;
      color: #25bd4f;
    }
    &.wrong {
      background-color: #fcdfdf;
      color: #e93333;
    }
    &.skipped {
      background-color: #dff2fc;
      color: #1aa1e9;
    }
    &.unattempted {
      background-color: #fcf1df;
      color: #f08d47;
    }
    &.Correct {
      background-color: #dffce7;
      color: #25bd4f;
    }
    &.Wrong {
      background-color: #fcdfdf;
      color: #e93333;
    }
    &.Skipped {
      background-color: #dff2fc;
      color: #1aa1e9;
    }
    &.un_attempted {
      background-color: #fcf1df;
      color: #f08d47;
    }
  }
  color: #4f4f4f;
  p.tag {
    padding: 5px 18px;
    text-align: center;
    width: max-content;
    margin-top: 10px;
    border-radius: 8px;
    background-color: #6666661a;
  }
}

.test__user-table {
  .ant-table-container {
    padding: 18px 50px !important;
  }
}
.test__user-table {
  .ant-table-thead > tr > th {
    padding: 12px 0px !important;
  }
  .attempt-table{
      .ant-table-container{
      }
  }
  // CHANGED
  // &.certificate {
  //   .ant-table-tbody,
  //   .ant-table-thead {
  //     tr {
  //       td,
  //       th {
  //         &:nth-child(3) {
  //           text-align: center;
  //         }
  //       }
  //     }
  //   }
  // }
  &.custom {
    .ant-table-tbody,
    .ant-table-thead {
      tr {
        td,
        th {
          &:nth-child(3) {
            text-align: center;
          }
        }
      }
    }
  }
}

.ant-collapse.ant-collapse-borderless.ant-collapse-icon-position-left.attempt-collapse {
  background: rgba($color: #000000, $alpha: 0.02) 0% 0% no-repeat padding-box;
  border-radius: 8px;
}
