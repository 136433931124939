@import "./variables";
@import "./helpers";

.ant-btn {
  border-radius: 26px;
  border: none;
  height: 42px;
  &::after {
    content: none;
  }
  &-primary {
    font-size: 15px;
    background: $primary-color !important;
    &:active,
    &:hover,
    &:focus {
      background-color: $primary-color;
    }
  }
  &.danger {
    background-color: #ff7676 !important;
  }
  &.success {
    background-color: #40ce2a !important;
  }
  &.blue {
    background-color: #4782f0 !important;
    height: 38px;
  }
  &-text {
    color: $input-color;
    &:active,
    &:hover,
    &:focus {
      color: $input-color;
      background-color: transparent;
    }
  }
  &.outlined {
    color: $primary-color;
    background-color: #fff !important;
    border: 1px solid $primary-color;
  }
  &.auth-outlined {
    border: 1px solid #6a6a6a3f;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active,
    &:hover,
    &:focus {
      color: #000;
    }
  }
  &.transparent {
    height: 38px;
    background-color: #ffffff1f !important;
    border-radius: 19px;
    box-shadow: none;
    &-round {
      margin-bottom: 12px;
      border-radius: 50%;
      padding: 0px 11px;
      height: 35px;
      background-color: rgba(255, 255, 255, 0.144);
      i::before {
        color: #fff;
      }
    }
  }
  &.invisible {
    background-color: transparent !important;
    i::before {
      font-size: 1.1rem;
      color: #fff;
    }
  }
  &[disabled] {
    color: #fff;
    opacity: 0.5;
    &:hover {
      color: #fff;
    }
  }
}

.ant-input,.ant-input-password {
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 0;
  box-shadow: none !important;
  color: $input-color !important;
  border-color: $border-color !important;
  &:active,
  &:hover,
  &:focus {
    box-shadow: none;
    border-color: $input-color;
  }
  &.link {
    border: none;
    padding: 10px 26px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font-size: 17px;
    line-height: 16px;
    color: #4f4f4f !important;
    height: 60px;
  }
}

.ant-input-group {
  .ant-input-group-addon {
    background-color: #fff !important;
    border-radius: 0 10px 10px 0 !important;
  }
}

.ant-tabs-nav {
  height: 42px;
  // padding: 0 30px;
  width: max-content;
  max-width: 100% !important;
  background: transparent linear-gradient(180deg, #f08d4736 0%, #eea94923 100%)
    0% 0% no-repeat padding-box;
  border-radius: 30px;
  .ant-tabs-tab,
  .ant-tabs-tab-btn {
    &:hover,
    &:focus,
    &:active,
    &:focus-visible,
    &:focus-within {
      color: #000 !important;
    }
  }
  .ant-tabs-nav-list {
    > .ant-tabs-tab:nth-last-child(2) {
      margin: 0;
    }
    .ant-tabs-tab{
      margin-right: 32px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    * {
      font-family: ProximaNovaExtraBold !important;
      color: #000 !important;
    }
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: $primary-color;
    // height: 3px;
    // border-radius: 0% 0% 50% 50%;
    // border-radius: 0 0 100px 100px;
    height: 5px;
    border-radius: 0 0 100% 100%;
  }
}

.ant-table-cell {
  &::before {
    background-color: transparent !important;
  }
}

.ant-select-selection-item {
  .ant-checkbox-wrapper {
    .ant-checkbox {
      display: none;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}

.ant-modal-wrap {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.72);
}
.ant-modal-mask {
  z-index: 999;
  // background: #fff 0% 0% no-repeat padding-box;
}

.ant-menu-horizontal {
  // border: none;
  border-bottom: none;
}

.ant-table-body {
  table {
    tr {
      cursor: pointer;
    }
  }
}

.ant-popover-placement-bottomRight {
  padding-top: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border-radius: 8px;
  }
}

.app-modal {
  .ant-modal-content {
    padding: 25px 20px !important;
    .ant-modal-header {
    }
    .ant-modal-title {
    }
  }
}

.ant-modal-content {
  // width: max-content !important;
  border-radius: 12px;
  overflow: hidden;
  // padding: 12px;
  .ant-modal-header {
    border: none;
    padding-bottom: 0;
  }
  .ant-modal-title {
    h2 {
      font: normal normal bold 19px/25px Proxima Nova;
    }
  }
}

// POPOVER RIGHT
.ant-popover.ant-popover-placement-right {
  width: 70% !important;
  left: 215px !important;
  border-radius: 8px;
  .ant-popover-title {
    padding: 15px !important;
    .image {
      background-color: $primary-color;
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      i {
        font-size: 6rem;
        &::before {
          color: #fff;
        }
      }
    }
  }
  .ant-popover-inner-content {
    h2 {
      color: #000;
      font: normal normal 600 32px/39px;
      font-weight: bolder;
    }
    p {
      color: #4f4f4f;
      font-size: 1rem;
      font: normal normal normal 16px/24px;
    }
  }
}

.ant-table.ant-table-middle.ant-table-fixed-header {
  border-radius: 8px;
}

.ant-select {
  width: 100%;
  .ant-select-selector {
    text-transform: capitalize;
    padding-left: 0 !important;
    font-size: .9rem;
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
      border: none;
      border-bottom: 1px solid $input-color;
    }
  }
  > div {
    width: 100% !important;
  }
}

.ant-picker {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  padding-left: 0 !important;
  font-size: 1rem;
  box-shadow: none !important;
  width: 100%;
  .ant-picker-input {
    border-bottom: none !important;
    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
      border: none;
      border-bottom: 1px solid $input-color;
    }
  }
  > div {
    width: 100% !important;
  }
}

// .ant-select-dropdown.ant-select-dropdown-empty.ant-select-dropdown-placement-bottomLeft.ant-slide-up-leave.ant-slide-up-leave-active.ant-slide-up{
//   display: none;
// }
// .ant-select-dropdown.deploy-hiringtest-select.ant-select-dropdown-empty.ant-select-dropdown-placement-bottomLef.ant-slide-up-leave.ant-slide-up-leave-active.ant-slide-up{
//   display: none;
// }
.deploy-hiringtest-select {
  // display: none;
}

//TABLE
.antd-table-full {
  overflow: hidden !important;
  .ant-table-wrapper {
    height: 100%;
    border-radius: 8px !important;
    overflow: hidden !important;
  }
  .ant-table-container {
    padding: 20px 35px;
  }
  .ant-table-header {
    thead {
      tr {
        th {
          background-color: #fff !important;
          font-weight: 700;
        }
      }
    }
  }
  .ant-table-body {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 10px;
    }
    table {
      // padding-bottom: 80px;
      colgroup {
        width: 120px;
      }
      tr {
        td {
          // padding: 20px 5px !important;
        }
      }
    }
  }
}

// .questions__list-table,
// .users__list-table {
//   max-height: 100% !important;
//   .ant-table-wrapper {
//     border-radius: 8px !important;
//     overflow: hidden !important;
//   }
//   .ant-table-container {
//     padding: 18px;
//   }
//   .ant-table-header {
//     thead {
//       tr {
//         th {
//           background-color: #fff !important;
//           font-weight: 700;
//         }
//       }
//     }
//   }
//   .ant-table-body {
//     overflow-y: scroll;
//     &::-webkit-scrollbar {
//       width: 10px;
//     }
//     table {
//       padding-bottom: 0px !important;
//       colgroup {
//         width: 120px;
//       }
//       tr {
//         td {
//           // padding: 20px 5px !important;
//         }
//       }
//     }
//   }
// }

.ant-typography.ant-typography-ellipsis.ant-typography-ellipsis-single-line {
  min-width: 50px;
  overflow: ellipsis;
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0 !important;
  &.bold {
    font-weight: bolder;
  }
}

.antd-whole-tab {
  &.two-tone {
    .ant-tabs-nav {
      height: 40px;
      background: #ececec;
    }
    .ant-tabs-tab {
      color: rgba(0, 0, 0, 0.199);
    }
    .ant-tabs-nav-list {
      > div:nth-child(2) {
        &.ant-tabs-tab.ant-tabs-tab-active {
          background-color: $menu-item-active;
        }
      }
    }
  }
  .ant-tabs-nav {
    &::before {
      display: none;
    }
    height: 38px;
    // padding: 0 30px;
    margin-bottom: 40px;
    width: max-content !important;
    max-width: 100%;
    background: transparent
      linear-gradient(180deg, #f08d4744 0%, #eea9492d 100%) 0% 0% no-repeat
      padding-box;
    border-radius: 26px;
    font-weight: 600;
    &-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          margin: 0;
          padding: 0px 30px;
          border-radius: 20px;
          &:nth-child(2) {
            margin: 0 !important;
          }
        }
      }
    }
    .ant-tabs-tab,
    .ant-tabs-tab-btn {
      &:hover,
      &:focus,
      &:active,
      &:focus-visible,
      &:focus-within {
        color: #000 !important;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      * {
        color: #fff !important;
        font-family: ProximaNovaBold !important;
      }
      background-color: $primary-color;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      display: none;
    }
  }
}

label.ant-radio-wrapper {
  > span {
    &:first-child {
      span {
        &:after {
          background-color: $primary-color;
        }
      }
    }
    &:last-child {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
    }
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: $primary-color !important;
  &:hover,
  &:active,
  &:focus,
  &::content {
    border-color: $primary-color !important;
  }
}
.ant-radio-input {
  &::selection {
    background-color: $primary-color !important;
  }
}

.ant-collapse.ant-collapse-borderless.ant-collapse-icon-position-left {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 0px 12px;
  .ant-collapse-item{
    border: 0;
  }
  .ant-collapse-header{
    font: normal normal 16px/24px ProximaNovaBold !important;
  }
}

.answer-row,.difficulty-level{
}
.ant-radio-inner{
  width: 18px;
  height: 18px;
  margin-right: 6px;
  &::after{
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    width: 26px;
    height: 26px;
    /* margin: auto; */
    left: 3px;
    top: 3px;
  }
}

.anticon.anticon-eye.ant-input-password-icon,.anticon.anticon-eye-invisible.ant-input-password-icon{
  svg{
    height: 14px;
    width: 14px;
  }
}

.ant-radio-checked .ant-radio-inner{
  border-color: $primary-color !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: $primary-color;
}

.ant-radio:hover .ant-radio-inner {
  border-color: $primary-color ;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $primary-color;
  &:hover,
  &:active,
  &:focus {
    border-color: $primary-color;
  }
}

// DRAWER

.ant-drawer-header-title{
  flex-direction: row-reverse;
  align-items: flex-start;
}

.ant-drawer-close{
  margin: 0;
}