.take-hiringtest {
  &__wrapper {
    margin: 30px 10px 30px 25px;
  }
  &__image {
    text-align: center;
    margin-bottom: 30px;
    img {
      width: 420px;
      height: 229px;
    }
  }
  &__link {
    display: grid;
    align-items: center;
    padding: 0px 40px 0 60px;
    height: 230px;
    width: 100%;
    background: transparent linear-gradient(180deg, #f08d47 0%, #eea849 100%) 0%
      0% no-repeat padding-box;
    border-radius: 12px;
    h2 {
      align-self: flex-end;
      margin-bottom: 20px;
      font: normal normal bold 24px/17px ProximaNova;
      color: #ffffff;
    }
    .link__form {
      align-self: flex-start;
      .ant-row {
        align-items: center;
      }
      button{
          padding: 0 30px;
      }
    }
  }
}
