@import "./../../../styles/variables";

.test__instructions {
  margin: 0px 30px;
  &-header {
    background: $primary-color;
    width: 100%;
    // height: 120px;
    border-radius: 12px;
    padding: 25px 30px;
    margin-bottom: 20px;
    position: relative;
    * {
      color: #fff;
    }
    .title__container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      .title {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        span {
          font-size: 18px;
        }
        > * {
          margin-right: 12px;
          cursor: pointer;
        }
        h1 {
          font: normal normal bold 24px/28px ProximaNova;
        }
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        font-size: 1rem;
        font: normal normal medium 16px/28px ProximaNova;
      }
    }
    div.footer {
      position: absolute;
      right: 20px !important;
      bottom: 10%;
      font-size: 1rem;
      font: normal normal medium 16px/28px ProximaNova;
    }
  }
  &-texts {
    min-height: 68vh;
    height: 100%;
    overflow-y: scroll;
    max-width: 100vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #00000005;
    border-radius: 8px;
    padding: 50px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    &::-webkit-scrollbar{
      width: 0;
    }
    &-list {
      .test__instructions-texts-tests {
        .ant-radio-wrapper {
          display: flex !important;
          align-items: baseline !important;
        }
        .test {
          max-height: 34px;
          align-items: baseline;
          h3 {
            font: normal normal medium 18px/24px ProximaNovaBlack !important;
          }
          > p {
            text-transform: capitalize;
            font: normal normal bold 16px/28px ProximaNovaBold !important;
            &.failed {
              i{
                &::before{
                  color: $failed-color !important;
                }
              }
              color: $failed-color;
            }
            &.success {
              color: $success-color;
            }
          }
          > span {
            color: $menu-item-active;
          }
          button {
            height: 30px;
          }
        }
      }
      h3 {
        font: normal normal bold 20px/24px ProximaNova;
      }
      ul {
        li {
          font-size: 18px;
          line-height: 32px;
          letter-spacing: 0px;
          color: #4f4f4f;
        }
      }
    }
    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        padding: 5px 30px;
      }
    }
  }
}

.start-test-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    img {
      object-fit: contain;
      height: 150px;
      width: 100%;
      margin-bottom: 20px;
    }
    h2 {
      font: normal normal bold 20px/12px ProximaNovaBold;
      margin-bottom: 25px;
    }
    .actions {
      button {
        font-size: 0.9rem;
        height: 39px;
        padding: 0 30px;
      }
      margin-bottom: 8px;
    }
  }
}
