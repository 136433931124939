@import "variables";
@import "helpers";
@import "antdOverides";
@import "icons";

@font-face {
  font-family: "ProximaNovaThin";
  font-style: normal;
  font-weight: 100;
  src: local("ProximaNova"), local("ProximaNova"),
  url("../assets/fonts/Proxima\ Nova\ Alt\ Thin.otf")
  format("truetype");
}
@font-face {
  font-family: "ProximaNovaRegular";
  font-style: normal;
  font-weight: 400;
  src: local("ProximaNova"), local("ProximaNova"),
  url("../assets/fonts/ProximaNova-Regular.otf")
  format("truetype");
}
@font-face {
  font-family: "ProximaNovaBlack";
  font-style: normal;
  font-weight: 500;
  src: local("ProximaNova"), local("ProximaNova"),
  url("../assets/fonts/Proxima\ Nova\ Black.otf")
  format("truetype");
}
@font-face {
  font-family: "ProximaNovaBold";
  font-style: normal;
  font-weight: 700;
  src: local("ProximaNova"), local("ProximaNova"),
  url("../assets/fonts/Proxima\ Nova\ Bold.otf")
  format("truetype");
}
@font-face {
  font-family: "ProximaNovaExtraBold";
  font-style: normal;
  font-weight: 800;
  src: local("ProximaNova"), local("ProximaNova"),
  url("../assets/fonts/Proxima\ Nova\ Extrabold.otf")
  format("truetype");
}
@font-face {
  font-family: "ProximaNovaAltThin";
  font-style: normal;
  font-weight: 100;
  src: local("ProximaNova"), local("ProximaNova"),
  url("../assets/fonts/Proxima\ Nova\ Alt\ Thin.otf")
  format("truetype");
}
@font-face {
  font-family: "ProximaNovaAltLight";
  font-style: normal;
  font-weight: 300;
  src: local("ProximaNova"), local("ProximaNova"),
  url("../assets/fonts/Proxima\ Nova\ Alt\ Light.otf")
  format("truetype");
}
@font-face {
  font-family: "ProximaNovaAltBold";
  font-style: normal;
  font-weight: 700;
  src: local("ProximaNova"), local("ProximaNova"),
  url("../assets/fonts/Proxima\ Nova\ Alt\ Bold.otf")
  format("truetype");
}

/* General component styles */
body {
  background-color: #F8F7F4;
  color: $primary-black;
  margin: 0;
  padding: 0;
}

body, h1, h2, h3, h4, h5, button, input {
  font-family: ProximaNovaRegular,'Roboto', sans-serif !important;
}

h1{
  font-family: ProximaNovaBold !important;
  font-size: 1.5rem;
}

.app-wrapper {
  padding-left: 255px;
  padding-right: 35px;
  padding-top: 2rem;
}

.share-view{
  padding: 45px;
}