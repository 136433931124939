@import "./../../styles/variables";
.auth-container {
  background-color: #f8f7f4;
  color: $input-color;
  height: 100vh;
  display: grid;
  grid-template-rows: 42% 58%;
  &__header {
    padding: 38px 0 25px 114px;
    background: transparent linear-gradient(124deg, #f08d47 0%, #eea849 100%) 0%
      0% no-repeat padding-box;
    * {
      color: #fff;
    }
    h1 {
      font-family: ProximaNovaBold !important;
      font-size: 2.5rem;
      width: 640px;
    }
    h3 {
      font-family: ProximaNovaRegular !important;
      margin-bottom: 20px;
      font-size: 1.5rem;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    .image__wrapper {
      img {
        margin-left: 20px;
        width: 50vw;
        height: 319px;
        height: 48vh;
        object-fit: contain;
      }
    }
    .form__wrapper {
      margin: auto;
      transform: translateY(-150px);
      overflow: hidden;
      // min-height: 400px;
      width: 433px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 12px 24px #0000000f;
      border-radius: 12px;
      padding: 40px;
      color: $input-color;
      height: max-content;

      // .ant-tabs-nav {
      //   &::before {
      //     display: none;
      //   }
      //   height: 38px;
      //   // padding: 0 30px;
      //   margin-bottom: 40px;
      //   width: max-content !important;
      //   max-width: 100%;
      //   background: transparent
      //     linear-gradient(180deg, #f08d4744 0%, #eea9492d 100%) 0% 0% no-repeat
      //     padding-box;
      //   border-radius: 26px;
      //   font-weight: 600;
      //   &-wrap {
      //     .ant-tabs-nav-list {
      //       .ant-tabs-tab {
      //         margin: 0;
      //         padding: 0px 30px;
      //         border-radius: 20px;
      //         &:nth-child(2) {
      //           margin: 0 !important;
      //         }
      //       }
      //     }
      //   }
      //   .ant-tabs-tab,
      //   .ant-tabs-tab-btn {
      //     &:hover,
      //     &:focus,
      //     &:active,
      //     &:focus-visible,
      //     &:focus-within {
      //       color: #000 !important;
      //     }
      //   }
      //   .ant-tabs-tab.ant-tabs-tab-active {
      //     * {
      //       font-weight: 600;
      //       color: #fff !important;
      //     }
      //     transition: 0.45s all;
      //     background-color: $primary-color;
      //   }
      //   .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      //     display: none;
      //     background: $primary-color;
      //     height: 3px;
      //     border-radius: 0 0 100px 100px;
      //   }
      // }

      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        input::placeholder {
          color: $input-color;
        }
        .password-field{
          margin-bottom: 0 !important;
        }
        > div:not(:last-child) {
          margin-bottom: 30px;
        }
        >button{
          margin-bottom: 18px;
        }
        &.auth-form {
          p.auth-text {
            font-size: 14px;
            color: #999999;
            &.center {
              text-align: center;
            }
            &.left {
              margin-top: 4px;
              text-align: left;
            }
          }
          .auth-outlined svg{
            height: 20px;
            width: 20px;
            margin-right: 10px;
          }
        }
        &.email__form,
        &.verify__form {
          > div {
            margin: 20px 0 60px 0;
          }
        }
        &.verify__form {
          .otp-container {
            padding: 3px 0;
            margin-left: 50px;
            margin-right: 50px;
            align-items: center;
            justify-content: space-between;
          }
          .otp-input {
            width: 2.5rem !important;
            outline: none;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #b2b4b5 !important;
            font-size: 1.8rem;
            color: #000;
            &:focus {
              outline: none !important;
            }
          }
        }
        &.reset__form {
          > * {
            margin-bottom: 35px;
          }
        }
        &.login__form {
          div {
            &:nth-child(3) {
              margin-bottom: 0;
            }
          }
        }
        .ant-btn-text {
          text-align: right;
          padding-right: 0;
        }
        .form-header {
          font-size: 2rem;
          font-weight: 600;
          &.login {
            color: $primary-color;
          }
          &.reset,
          &.verify,
          &.email {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .terms_and_service{
    &-header{
      font-size: 24px;
      font-family: ProximaNovaBold !important;
    }
    &-content{
      p{
        color: #4F4F4F;
        font-size: 16px;
      }
    }
    button{
      width: 75%;
    }
  }
}
