@import "./../../styles/variables";

.deploy_hiringtest {
  margin: 0 40px;
  &-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    &-banner {
        cursor: pointer;
      position: relative;
      background-color: #fff;
      border-radius: 8px;
      padding: 40px;
      h2 {
        font-size: 1.3rem;
        margin-bottom: 30px;
        font-family: ProximaNovaBold !important;
      }
      img {
        display: flex;
        align-items: center;
        margin: auto;
        object-fit: contain;
        width: 305px;
        margin-bottom: 30px;
      }
      ul {
          padding-left: 15px;
          font-size: 1rem;
      }
    }
  }
}
