@import "../../../styles/variables";
@import "../../../styles/_icons.scss";

.app-sidebar {
  width: 235px;
  background-color: #ffffff;
  box-shadow: 0px 3px 24px #00000005;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  // padding: 1rem 0;
  z-index: 1001 !important;
  padding: 0 !important;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;


#user-badge{
  font: 100 !important;
  font-size: 13px !important;
  font: normal normal normal 13px/16px ProximaNovaRegular;
}
  .ant-menu {
    color: #6a6a6a !important;
  }

  .ant-menu-item {
    padding: 1.8rem 24px !important;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    // height: auto;
    margin: auto;
    display: grid;
    place-content: center;
    grid-template-columns: 40px 1fr;
    margin-top: 0 !important;
    margin-bottom: 15px !important;

    i {
      font-size: 1.4rem;
    }
    &:hover {
      color: $primary-black;
    }

    .app-sidebar__menu-title {
      line-height: 1.5rem;
      font-size: 15px;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff;
    border-left: 4px solid $menu-item-active;
    font-weight: bold;
    background-color: #f6f9fe;
    width: 100%;
    color: $menu-item-active;
    font: normal normal bold 14px/38px ProximaNovaBold;
    i::before,
    div:not(.app-sidebar__menu-title) {
      color: $menu-item-active !important;
    }
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border: none;
  }

  #profile-link {
    bottom: 10px;
    position: fixed;
    width: 214px;
    margin: auto 10px !important;
    background-color: blanchedalmond !important;
    // margin: 10px 10px 0 10px !important;
    margin-top: 56px !important;
    max-width: 89%;
    background: transparent linear-gradient(180deg, #f08d47 0%, #eea849 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    height: 100% !important;
    padding: 10px 2px !important;
    color: #fff !important;
    border: none !important;
    padding: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 145px !important;
    .ant-menu-title-content{
      margin: 0;
      text-align: center;
    }
  }
  .popover-btn {
    position: absolute;
    top: 3%;
    right: 2%;
    color: #fff;
    font-weight: bolder;
    background-color: rgba(255, 255, 255, 0.19);
    border-radius: 3px;
    padding: 1px 10px !important;
    height: 20px;
    z-index: 10;
    &:hover,
    &:active,
    &:focus {
      color: #fff;
    }
    span {
      font-size: 17px;
      margin: auto;
    }
  }
}

.app-sidebar__brand-logo {
  display: block;
  margin: 1rem auto 1rem auto;
  width: 100%;
  height: 120px;
  cursor: pointer;
  object-fit: none;
}

.app-sidebar::-webkit-scrollbar {
  display: none;
}

.popover-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li:not(:last-child) {
    margin: 0 0 8px 0;
  }
  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #4f4f4f;
    font-size: 0.9rem;
    i {
      font-size: 1.08rem !important;
      margin-right: 8px;
    }
  }
}

.ant-menu-title-content {
  img {
    border-radius: 50%;
    margin-bottom: 4px;
    margin-top: 5px;
  }
}

.quick_navigation-modal {
  h2{
    font: normal normal bold 24px/29px ProximaNovaBold !important;
  }
  #quick_navigator {
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    // padding: 50px 30px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    font: normal normal medium 18px/22px !important;
    width: 100%;
    h3{
      font: normal 17px/22px ProximaNovaRegular !important;
      font-weight: 600 !important;
    }
  }
  .ant-modal-title {
    h2 {
      color: $primary-color;
      font: normal normal bold 24px/29px ProximaNovaBold;
    }
  }
}

.ant-modal-wrap.ant-modal-centered {
  z-index: 1001;
}

.app-sidebar__container {
  img, #profile-link i {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px !important;
    width: 58px !important;
    border-radius: 50%;
    margin-bottom: 4px;
    margin-top: 5px;
  }
}
