@import "./../../../styles/variables";

.deploy_hiringtest_wrapper {
  margin: 0 20px;
  &-link{
    height: 100%;
    background: #fff;
    padding-left: 0;
    .ant-input.link{
      background-color: #F0F0F0;
    }
  }
  &-header {
  }
  &-container {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    .form {
      width: 100%;
      border-radius: 8px;
      height: 70vh;
      background-color: #fff;
      padding: 50px;
      margin-bottom: 30px;
      .deploy_hiringtest_form {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        &-fields {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 40px;
          &.certificate {
            // grid-template-columns: 0.5fr;
            .ant-select-selection-item{
              font-size: 18px;
              font-weight: 600;
            }
          }
          > div {
            position: relative;
            margin-bottom: 28px;
            label {
              font-size: 16px;
            }
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            .input__error{
              position: absolute;
              top: 38px;
            }
            input {
              &::placeholder {
                font-weight: 400;
              }
              font-weight: 600;
              font-size: 16px;
              color: #000 !important;
            }
            &:nth-child(7) {
              &::after {
                content: "(Optimal number of questions - 75 questions with 3 options and 15 seconds per question)";
              }
            }
            &:nth-child(8) {
              justify-content: flex-start;
            }
          }
          input {
            font-size: 1rem;
          }
        }
        &-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: absolute;
          bottom: 10%;
          right: 80px;
          button {
            padding: 5px 30px;
          }
        }
      }
    }
  }
}
.deploy-hiringtest-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .ant-input-group-wrapper {
    margin-left: 40px;
    width: 280px;
    .ant-input {
      border: 1px solid $border-color;
      border-right: none !important;
      padding: 8px 14px;
      border-radius: 10px 0 0 10px;
      color: $input-color !important;
      &:active,
      &:hover,
      &:focus {
        box-shadow: none;
        border-color: $border-color;
      }
    }
  }
}

.select-topic {
  &-content {
    // CHANGED
    .topics,.tests {
      height: 650px;
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #fff; 
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #E5E5E5;
        border-radius: 10px;
      }
      .ant-radio-group.ant-radio-group-outline {
        display: flex;
        flex-direction: column;
        .ant-radio-wrapper {
          display: flex;
          align-items: baseline;
          margin-bottom: 20px;
          color: #6a6a6a;
          font: normal normal bold 16px/20px ProximaNovaBold;
          &-checked {
            color: $primary-color;
          }
        }
        // .ant-radio-inner {
        //   width: 20px;
        //   height: 20px;
        //   &::after {
        //     top: 3px;
        //     left: 3px;
        //     width: 12px;
        //     height: 12px;
        //   }
        // }
      }
    }
    .tests {
      // CHANGED
      // .ant-radio-group.ant-radio-group-outline {
      //   .ant-radio-wrapper {
      //     margin-bottom: 16px;
      //     font: normal normal bold 16px/20px ProximaNovaBold;
      //   }
      //   .ant-radio-inner {
      //     width: 17px;
      //     height: 17px;
      //     &::after {
      //       top: 2px;
      //       left: 2px;
      //       width: 11px;
      //       height: 11px;
      //     }
      //   }
      // }
      
      &::-webkit-scrollbar {
        width: 0px;
      }
      // /* Track */
      // &::-webkit-scrollbar-track {
      //   box-shadow: inset 0 0 5px #fff; 
      //   border-radius: 10px;
      // }
      // /* Handle */
      // &::-webkit-scrollbar-thumb {
      //   background: rgb(209, 209, 209); 
      //   border-radius: 10px;
      // }
      height: 570px;
      overflow-y: scroll;
      padding: 35px 40px;
      background-color: #fbfbfb;
      h2 {
        position: sticky;
        font-size: 18px;
        font-family: ProximaNovaBold !important;
      }
      .ant-checkbox-group{
        label{
          margin-bottom: 15px;
          span{
            &.ant-checkbox{
              
            }
            &:last-child{
              font: normal normal bold 16px/20px ProximaNovaBold;
            }
          }
        }
      }
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 20px;
    right: 80px;
    button {
      padding: 5px 30px;
    }
  }
}

.select-topic-drawer{
  .ant-drawer-body{
    overflow: hidden;
  }
}
