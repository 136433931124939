@import "./../../styles/variables";

.questions__list {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 25px;
      i {
        margin-right: 10px;
      }
    }
  }
  &-table {
    margin-top: 15px;
    .ant-table-tbody {
      td {
        padding: 5px !important;
      }
    }
    p.status {
      padding: 5px 18px;
      text-align: center;
      width: max-content;
      margin-top: 10px;
      border-radius: 8px;
      text-transform: capitalize;
      &.approved {
        background-color: #dffce7;
        color: #25bd4f;
      }
      &.rejected {
        background-color: #fcdfdf;
        color: #e93333;
      }
      &.pending_approval {
        background-color: #dff2fc;
        color: #1aa1e9;
      }
    }
  }
}
.filter-drawer {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      padding: 25px;
    }
  }
  .ant-drawer-body {
    height: 100%;
    form {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      .ant-col {
        h3 {
          font-size: 14px;
          color: #9e9e9e;
        }
        .ant-checkbox-wrapper {
          font-size: 15px;
          color: #4f4f4f;
        }
      }
    }
  }
  .ant-checkbox-group {
    display: flex !important;
    flex-direction: column !important;
  }
  .anticon-close {
    font-size: 22px;
    font-weight: bolder;
    color: #000;
    padding-bottom: 15px;
  }
  .filter-controller {
    button {
      padding: 0 30px;
    }
  }
}

.contribute-question-modal {
  .certificate-card,
  .custom-card {
    cursor: pointer;
    padding: 35px 40px;
    border: 1px solid #b6b6b68e;
    border-radius: 8px;
    img {
      height: 100%;
      width: 100%;
      width: 285px;
      height: 200px;
    }
    h2 {
      text-align: center;
      font: normal normal bold 18px/12px ProximaNovaBold !important;
      margin-top: 20px;
      // margin: 20px 0;
    }
  }
}

.access-code-modal {
  input{
    font-size: 1rem;
  }
  button {
    padding: 0 30px;
  }
}

.cutomtest-access-modal{
  button{
    height: 40px;
    padding: 0 30px !important;
    span{
      font-size: 1rem !important;
    }
  }
}

.topics-filter-title{
  flex-direction: column;
  align-items: flex-start;
  h1{
    margin-bottom: 20px;
  }
  span{
    margin-left: 0 !important;
  }
}