@import "./../../../styles/variables";

.propose__topic {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 1.5rem;
      font-weight: 800 !important;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
    }
    .notification-note {
      margin-bottom: 20px;
      background: #f3f7ff 0% 0% no-repeat padding-box;
      border: 0.5px solid #4782f0;
      border-radius: 5px;
      width: 600px;
      .ant-col {
      }
      .ant-col-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4782f0 !important;
        i {
          font-size: 1.5rem;
          &::before {
            color: #fff;
          }
        }
      }
      .ant-col-22 {
        h4 {
          font-weight: bold;
        }
        * {
          margin-bottom: 0;
        }
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 10px 20px !important;
      }
    }
  }

  .topic-form__container {
    height: 80vh;
    max-width: 100vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #00000005;
    border-radius: 8px;
    padding: 50px;
  }
  .topic__form {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    &-fields {
      .delete-btn {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #ff7676 !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 25px;
        i {
          margin-right: 10px;
        }
      }
      > span > div:first-child {
        position: relative;
        background: #fffaf6 0% 0% no-repeat padding-box;
        border: 1px dashed $primary-color !important;
        width: 100%;
        height: 100%;
        max-height: 200px;
        max-width: 350px;
        overflow: hidden;
        border-radius: 10px;
        img {
          background-color: #fff;
          position: absolute;
          object-fit: fill;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      > div,
      > span > div {
        width: 35% !important;
      }
      > div,
      > span > div:last-child,
      textarea {
        margin-bottom: 60px;
      }
      textarea {
        width: 85%;
        max-height: 100px;
      }
      .ant-upload-text {
        margin: auto;
        color: #4f4f4f;
        i {
          margin-right: 20px;
          padding: 15px;
          border-radius: 50%;
          background-color: $primary-color;
          &::before {
            color: #fff;
          }
        }
      }
      .ant-input {
        font-size: 1rem;
        color: #4f4f4f !important;
      }
    }
    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        padding: 5px 30px;
      }
    }
  }

  .delete-modal {
    width: 562px !important;
    .ant-modal-content {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 281px;
      border-radius: 18px;
      overflow: hidden;
    }
    .ant-modal-body {
      h3 {
        font-size: 20px;
        font-weight: 600;
      }
      button {
        padding: 5px 35px;
        margin-right: 20px;
        &.cancel {
          color: #6a6a6a;
          background-color: #f8f7f4 !important;
        }
      }
    }
  }
}
