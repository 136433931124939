@import "./../../../styles/variables";

.pending-approval__question {
  .question-view-correct-answer-image{
    width: 100%;
  }
  .correct_answer_image{
    max-height: 200px;
    max-width: 350px;
  }
  .answers_grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    .answer_item{
      border-radius: 8px;
      padding: 20px;
      border: 1px solid rgba(128, 128, 128, 0.253);
      img{
        max-width: 240px;
        max-height: 185px;
      }
    }
  }
  &-header {
    .title {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      > div {
        &:first-child {
          > * {
            margin-right: 15px;
          }
          display: flex;
          align-items: baseline;
          justify-content: space-between;
        }
        &:last-child {
          p {
            font-size: 14px;
            color: $primary-color;
            span {
              color: #000;
              font-weight: 600;
            }
          }
        }
      }
      h1 {
        font-weight: 600 !important;
      }
      h1 {
        font-size: 1.5rem;
      }
      > span {
        font-size: 1.2rem;
      }
      button {
        cursor: default;
        height: 35px !important;
        padding: 0 30px;
      }
    }
  }
  &-content {
    position: relative;
    border-radius: 8px;
    height: 85vh;
    overflow-y: scroll;
    max-width: 100vw;
    background-color: #fff;
    padding: 50px 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    &::-webkit-scrollbar{
      width: 0;
    }
    .edit-icon {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      i {
        font-size: 1.2rem;
        color: $primary-color;
      }
    }
    .texts {
      .correct_answer-header,
      .answer-header,.question-text {
        margin-top: 40px;
      }
      p {
        color: #7e7e7e;
        font-size: 1rem;
        margin-bottom: 0;
      }
      h3,
      h2 {
        color: rgba(0, 0, 0, 0.74);
        font-weight: 600;
      }
      .topic-text {
        h2 {
          font: normal normal bold 19px/28px ProximaNovaBold !important;
        }
      }
      .question-text {
        h2 {
          font: normal normal normal 18px/24px ProximaNovaRegular !important;
        }
      }
      h3 {
        margin-bottom: 0;
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        span {
          margin-right: 5px;
        }
      }
    }
    .image {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;
      .image-wrapper {
        width: 100%;
        height: 320px;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      .actions {
        button {
          padding: 0 25px;
          &:last-child {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
